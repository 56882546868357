<template>
  <div class="patent-evaluate">
    专利评估
  </div>
</template>

<script lang="ts">
</script>

<style lang="less" scoped>

</style>
